import _, { reject } from 'lodash';

export function list ($, token, resolve, reject, complete) {
  console.log('insights.list... ');

  $.$api.SSP
    .get('/insights/', {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log('insights.list => ', response.data);

      resolve(response.data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        $.getout('insights.list');
        $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        $.handleError(error);
        if ($.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if ($.isValid(complete)) complete();
    });
};

export function get ($, token, prompt, id, resolve, reject, complete) {
  console.log('insights.get... ', prompt, id);

  $.$api.SSP
    .post('/insights/'+(_.isNil(id) ? '' : id), { prompt }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('insights.get => ', response.data);
      resolve(response.data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        $.getout('insights.get');
        $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        $.handleError(error);
        if ($.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if ($.isValid(complete)) complete();
    });
};

export function feedback ($, token, id, score, resolve, reject, complete) {
  console.log('insights.feedback... ', id, score);

  $.$api.SSP
    .put('/insights/'+id, { score }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('insights.feedback => ', response.data);

      resolve(response.data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        $.getout('insights.feedback');
        $.handleError(error, 'Sua sessão expirou...', true);
      }else{
        $.handleError(error);
        if ($.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if ($.isValid(complete)) complete();
    });
};

export default {
  list,
  get,
  feedback
}
